import React from "react";
import { connect } from "react-redux";
import Error404 from "../Components/ErrorPages/Error404";

class ErrorPageContainer extends React.Component {
	render() {
		return this.props.show404Error ? <Error404 /> : null;
	}
}

const mapActionsToProps = {};

const mapDataToProps = (state) => {
	return {
		show404Error: state.user && state.user.show404Error,
	};
};
export default connect(mapDataToProps, mapActionsToProps)(ErrorPageContainer);
