import { MessageTypeConstants } from "../../../../Services/Message";
import _ from "lodash";
import { showSnackbarV2 } from "../../../Store/Notification/NotificationAction";
import store from "../../../../State/configureStore";

const updateComponents = (components, message, promptOnClose) => {
	let updatedComponents = components.map((item) => {
		let newItem = { ...item };

		const isMatchingParentDocId =
			newItem?.options?.docId === message?.options?.parentDocId;
		const isMatchingTabId = newItem?.options?.tabId === message?.options?.tabId;

		if (
			(newItem?.options?.docId &&
				message?.options?.parentDocId &&
				isMatchingParentDocId) ||
			message?.options?.parent
		) {
			if (newItem?.options?.tabId && isMatchingTabId) {
				switch (newItem.messageType) {
					case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
						newItem.message = handleContainerMessageType(newItem, message);
						break;
				}
				newItem.options.pages = message.options.pages;
			}
			if (promptOnClose !== undefined) {
				newItem.options.promptOnClose = promptOnClose;
			}
		} else {
			if (
				newItem?.options?.tabId &&
				message?.options?.tabId &&
				isMatchingTabId
			) {
				let newFields = message.message.fields;
				switch (newItem.messageType) {
					case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
						newItem.message = handleCalendarMessageType(
							newItem,
							message,
							newFields
						);
						break;
					case MessageTypeConstants.MESSAGE_TYPE_FORM2:
					case MessageTypeConstants.MESSAGE_TYPE_TABLE:
					case MessageTypeConstants.MESSAGE_TYPE_MAP:
						newItem.message = handleOtherMessageTypes(newItem, message);
						break;
				}
				if (promptOnClose !== undefined) {
					newItem.options.promptOnClose = promptOnClose;
				}
				newItem.options.pages = message.options.pages;
			}
		}
		return newItem;
	});
	return updatedComponents;
};

export const handleMessageTypeTableChangeAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		const updatedComponents = updateComponents(
			NonConversationalData.components,
			message,
			promptOnClose
		);
		let updatedNonConversationalData = {
			...NonConversationalData,
			components: updatedComponents,
		};
		return updatedNonConversationalData;
	}
	return NonConversationalData;
};

const handleContainerMessageType = (item, message) => {
	const updatedMessages = item.message.map((msg) => {
		if (
			message?.options &&
			msg?.message?.options?.tableId === message?.options?.tableId
		) {
			const updatedMsg = handleTableMessage(msg, message);
			updatedMsg.message.options.pages = message.options.pages;
			return updatedMsg;
		}
		return msg;
	});
	return updatedMessages;
};

const handleCalendarMessageType = (item, message, newFields) => {
	if (message?.message?.update) {
		newFields = message?.message?.update;
	} else if (message?.message?.append) {
		newFields = item.message.concat(message.message.append);
	} else if (message?.message?.fields) {
		newFields = message?.message?.fields;
	}
	item.options.view = item.options.view || message?.options?.view || "month";
	item.options.style =
		item.options.style || message?.options?.style || "calendar";
	item.options.openDrawer = item.options.openDrawer || false;
	item.options.showFilterSearchInput =
		item.options.showFilterSearchInput || false;
	item.options.isNewFilter = item.options.isNewFilter || false;
	return newFields;
};

const handleOtherMessageTypes = (item, message) => {
	let updatedMessage;
	if (message?.message?.deleteRow) {
		updatedMessage = item.message?.filter(
			(row) => !message.message.deleteRow.includes(Object.keys(row)[0])
		);
	} else if (message?.message?.update) {
		updatedMessage = message?.message?.update;
	} else if (message?.message?.append) {
		updatedMessage = item.message.concat(message.message.append);
	} else if (message?.message?.fields) {
		updatedMessage = updateMessageFields(item, message);
	} else {
		updatedMessage = item.message;
	}
	return updatedMessage;
};

const handleTableMessage = (msg, message) => {
	const updatedMsg = { ...msg };
	if (message?.message?.deleteRow) {
		updatedMsg.message.rows = msg.message.rows?.filter(
			(field) => !message.message.deleteRow.includes(Object.keys(field)[0])
		);
	} else if (message?.message?.update) {
		updatedMsg.message.rows = message.message.update;
	} else if (message?.message?.remove) {
		updatedMsg.message.rows = removeFieldsFromRows(msg, message);
	} else if (message?.message?.append) {
		updatedMsg.message.rows = msg.message.rows.concat(message.message.append);
	} else if (message?.message?.fields) {
		updatedMsg.message.rows = updateRowsWithNewFields(msg, message);
	}
	return updatedMsg;
};

const removeFieldsFromRows = (msg, message) => {
	let updatedRows = [];

	msg.message.rows.forEach(([docId, rowValue]) => {
		if (docId === message.options.docId) {
			let updatedRowValue = rowValue.filter(
				(field) => !message.message.remove.includes(field.id)
			);
			updatedRows.push({ [docId]: updatedRowValue });
		} else {
			updatedRows.push({ [docId]: rowValue });
		}
	});

	return updatedRows;
};

const updateMessageFields = (item, message) => {
	let updatedMessage = [];

	item.message.forEach((row) => {
		// let updatedRow = [];

		Object.entries(row).forEach(([docId, rowValue]) => {
			if (
				message?.options?.docId &&
				docId &&
				message?.options?.docId === docId
			) {
				const updatedRowValue = updateRowFields(rowValue, message);
				updatedMessage.push({ [docId]: updatedRowValue });
			} else {
				updatedMessage.push({ [docId]: rowValue });
			}
		});

		// updatedMessage.push(updatedRow);
	});

	return updatedMessage;
};

const updateRowFields = (rowValue, message) => {
	let fieldsList = [];
	let updatedFields = [];

	rowValue.fields.forEach((field) => {
		fieldsList.push(field.id);

		const newField = message.message.fields.find((f) => f.id === field.id);
		if (newField) {
			updatedFields.push(newField);
		} else {
			updatedFields.push(field);
		}
	});

	const finalFields = addNewFields(updatedFields, message, fieldsList);

	return {
		...rowValue,
		fields: finalFields,
	};
};

const addNewFields = (fields, message, fieldsList) => {
	let newFields = [];

	message.message.fields.forEach((newField) => {
		if (!fieldsList.includes(newField.id)) {
			newFields.push(newField);
		}
	});

	newFields = newFields.concat(fields);

	if (newFields.find((field) => field.index)) {
		newFields.sort((a, b) =>
			a.index > b.index ? 1 : b.index > a.index ? -1 : 0
		);
	}

	return newFields;
};

const updateRowsWithNewFields = (msg, message) => {
	let updatedRowsWithNewFields = msg.message.rows.map((row) => {
		let updatedRowFields = Object.entries(row).map(([docId, rowValue]) => {
			if (
				message?.options?.docId &&
				docId &&
				message?.options?.docId === docId
			) {
				let updatedRowFields = updateRowFields(rowValue, message);
				return { [docId]: updatedRowFields };
			}
			return { [docId]: rowValue };
		});
		return updatedRowFields[0];
	});
	return updatedRowsWithNewFields;
};

export const handleMessageTypeInlineFormTableResultAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		NonConversationalData.components.forEach((item) => {
			//message has a parent case handling
			if (
				item?.options?.docId &&
				message?.options?.parentDocId &&
				item?.options?.docId === message?.options?.parentDocId
			) {
				if (message.message.field) {
					item.message.map((msg) => {
						if (msg?.message?.options?.tableId === message?.options?.tableId) {
							msg.message.rows.map((row) => {
								Object.entries(row).map(([docId, fieldsObject]) => {
									if (message?.options?.docId === docId) {
										fieldsObject.fields.map((field) => {
											if (field.id === message?.message?.field) {
												field.results = message?.message?.results;
												field.validationResult =
													message?.message?.validationResult;
												field.validationMessage =
													message?.message?.validationMessage;
											}
											return field;
										});
									}
								});
							});
						}
					});
				} else {
					item.genericError = message.message;
					if (!message.message.validationResult) {
						store.dispatch(
							showSnackbarV2("error", message.message.validationMessage)
						);
					}
				}

				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			} else {
				if (message.message.field) {
					if (item?.options?.tableId === message?.options?.tableId) {
						Array.isArray(item.message) &&
							item.message.map((row) => {
								Object.entries(row).map(([docId, fieldsObject]) => {
									if (message?.options?.docId === docId) {
										fieldsObject.fields.map((field) => {
											if (field.id === message?.message?.field) {
												field.results = message?.message?.results;
												field.validationResult =
													message?.message?.validationResult;
												field.validationMessage =
													message?.message?.validationMessage;
											}
											return field;
										});
									}
								});
							});
					}
				} else {
					item.genericError = message.message;
					if (!message.message.validationResult) {
						store.dispatch(
							showSnackbarV2("error", message.message.validationMessage)
						);
					}
				}

				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			}
		});
		return NonConversationalData;
	}
};

export const handleMessageTypeTableChangeColumnTemplate = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		const updatedComponents = NonConversationalData.components.map((item) => {
			let updatedItem = { ...item };

			if (
				updatedItem?.options?.docId &&
				message?.options?.parentDocId &&
				updatedItem?.options?.docId === message?.options?.parentDocId
			) {
				if (
					updatedItem?.options?.tabId &&
					updatedItem?.options?.tabId === message?.options?.tabId
				) {
					switch (updatedItem.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
							updatedItem.message = updatedItem.message.map((msg) => {
								if (
									message?.options &&
									msg?.message?.options?.tableId === message?.options?.tableId
								) {
									if (message.options.columnTemplate) {
										return {
											...msg,
											message: {
												...msg.message,
												options: {
													...msg.message.options,
													columnTemplate: message.options.columnTemplate,
												},
											},
										};
									}
								}
								return msg;
							});
							break;
					}
				}

				if (promptOnClose !== undefined) {
					updatedItem = {
						...updatedItem,
						options: {
							...updatedItem.options,
							promptOnClose: promptOnClose
						}
					};
				}
			} else {
				if (
					updatedItem?.options?.tabId &&
					message?.options?.tabId &&
					updatedItem?.options?.tabId === message?.options?.tabId
				) {
					switch (updatedItem.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
						case MessageTypeConstants.MESSAGE_TYPE_FORM2:
						case MessageTypeConstants.MESSAGE_TYPE_TABLE:
							if (message.options.columnTemplate) {
								updatedItem = {
									...updatedItem,
									options: {
										...updatedItem.options,
										columnTemplate: message.options.columnTemplate,
									},
								};
							}
							break;
					}

					if (promptOnClose !== undefined) {
						updatedItem = {
							...updatedItem,
							options: {
								...updatedItem.options,
								promptOnClose: promptOnClose
							}
						};
					}
				}
			}
			return updatedItem;
		});

		const updatedNonConversationalData = {
			...NonConversationalData,
			components: updatedComponents,
		};

		return updatedNonConversationalData;
	}

	return NonConversationalData;
};

export const handleMessageTypeForm2ChangeAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		// check which item in app it want to update
		NonConversationalData.components.forEach((item) => {
			// check if it's in a component like container
			if (message?.options?.parent) {
				if (
					item?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
							item.message.forEach((msg) => {
								if (
									message?.options &&
									msg?.message?.options?.formId === message?.options?.formId
								) {
									if (message?.message?.remove) {
										msg.message.fields = msg.message.fields?.filter(
											(field) => !message.message.remove.includes(field.id)
										);
									} else if (message?.message?.update) {
										msg.message.fields = msg.message.fields?.map((field) =>
											message.message.update.id === field.id
												? message.message.update
												: field
										);
									} else if (message?.message?.fields) {
										for (let i = 0; i < message.message.fields.length; i++) {
											const newFiled = message.message.fields[i];
											let fieldsList = [];
											// Update the fileds
											msg.message.fields = msg.message.fields?.map((field) => {
												fieldsList.push(field.id);
												if (field.id === newFiled.id) {
													field = newFiled;
												}
												return field;
											});

											// Incase new filed is added
											if (!fieldsList.includes(newFiled.id)) {
												msg.message.fields = [...msg.message.fields, newFiled];
												// Sort if index is present
												if (newFiled.index) {
													msg.message.fields = msg.message.fields.sort((a, b) =>
														a.index > b.index ? 1 : b.index > a.index ? -1 : 0
													);
												}
											}
										}
									}
								}
							});
							break;
					}
					// if the any changes happens
					// The reason for checking undefined because we don't want to change the value which is previously set
					if (promptOnClose !== undefined)
						item.options.promptOnClose = promptOnClose;
				}
			} else {
				// if it's independent form
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_FORM2: {
							let newFields = message?.message?.fields;

							if (message?.message?.update) {
								newFields = message?.message?.update;
							} else if (message?.message?.fields) {
								newFields = message?.message?.fields;
							}

							for (let i = 0; i < newFields.length; i++) {
								let fieldsList = [];
								// Update the fileds
								item.message = item?.message?.map((field) => {
									fieldsList.push(field.id);
									if (field.id === newFields[i].id) {
										field = newFields[i];
									}
									return field;
								});

								// Incase new filed is added
								if (!fieldsList.includes(newFields[i].id)) {
									item.message = [...item.message, newFields[i]];
									// Sort if index is present
									if (newFields[i].index) {
										item.message = item?.message.sort((a, b) =>
											a.index > b.index ? 1 : b.index > a.index ? -1 : 0
										);
									}
								}
							}
							break;
						}
					}
					// if the any changes happens
					// The reason for checking undefined because we don't want to change the value which is previously set
					if (promptOnClose !== undefined)
						item.options.promptOnClose = promptOnClose;
				}
			}
		});
	}
	return NonConversationalData;
};

export const handleMessageTypeForm2ResultAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		NonConversationalData.components.forEach((item) => {
			if (
				item?.options?.controlId &&
				message?.options?.controlId &&
				item?.options?.controlId === message?.options?.controlId
			) {
				switch (item.messageType) {
					case MessageTypeConstants.MESSAGE_TYPE_FORM2:
						if (message.message.field) {
							item.message.map((field) => {
								if (field.id === message.message.field) {
									field.results = message.message.results;
									field.validationResult = message.message.validationResult;
									field.validationMessage = message.message.validationMessage;
								}
								return field;
							});
						} else {
							item.genericError = message.message;
							if (!message.message.validationResult) {
								store.dispatch(
									showSnackbarV2("error", message.message.validationMessage)
								);
							}
						}
						break;
				}
				// if the any changes happens
				// The reason for checking undefined because we don't want to change the value which is previously set
				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			} else if (
				item?.options?.controlId &&
				message?.options?.controlId &&
				item?.options?.controlId === message?.options?.parent
			) {
				// if the form is inside the conatiner
				item.message.map((msg) => {
					if (
						msg?.message?.options?.controlId === message?.options?.controlId
					) {
						msg.message.fields.map((field) => {
							if (field.id === message.message.field) {
								field.results = message.message.results;
								field.validationResult = message.message.validationResult;
								field.validationMessage = message.message.validationMessage;
							}
							return field;
						});
					}
				});

				// if the any changes happens
				// The reason for checking undefined because we don't want to change the value which is previously set
				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			}
		});
	}
	return NonConversationalData;
};
