import React from "react";
import {
	VOYAGER_LANDING,
	VIKAND_LANDING,
	INTELL4DESK_LANDING,
	THURAYA_LANDING,
	VOYAGER_LOGIN_MAIN_HEADER_TEXT,
	THURAYA_LOGIN_MAIN_HEADER_TEXT,
	VOYAGER_LOGIN_SUB_HEADER_TEXT,
	THURAYA_LOGIN_SUB_HEADER_TEXT,
	VOYAGER_LOGIN_MAIN_HEADER_TEXT_COLOR,
	VOYAGER_LOGIN_SUB_HEADER_TEXT_COLOR,
	THURAYA_LOGIN_MAIN_HEADER_TEXT_COLOR,
	THURAYA_LOGIN_SUB_HEADER_TEXT_COLOR,
	STATION_SATCOM_LANDING,
	STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT,
	STATION_SATCOM_LOGIN_SUB_HEADER_TEXT,
	STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT_COLOR,
	STATION_SATCOM_LOGIN_SUB_HEADER_TEXT_COLOR,
	VIKAND_LOGIN_MAIN_HEADER_TEXT,
	INTELL4DESK_LOGIN_MAIN_HEADER_TEXT,
	VIKAND_LOGIN_SUB_HEADER_TEXT,
	INTELL4DESK_LOGIN_SUB_HEADER_TEXT,
	VIKAND_LOGIN_MAIN_HEADER_TEXT_COLOR,
	INTELL4DESK_LOGIN_MAIN_HEADER_TEXT_COLOR,
	VIKAND_LOGIN_SUB_HEADER_TEXT_COLOR,
	INTELL4DESK_LOGIN_SUB_HEADER_TEXT_COLOR,
	AAGEHEMPEL_LANDING,
	AAGEHEMPEL_LOGIN_MAIN_HEADER_TEXT,
	AAGEHEMPEL_LOGIN_SUB_HEADER_TEXT,
	VIKAND_DIRECT_LANDING,
	VIKAND_DIRECT_LOGIN_SUB_HEADER_TEXT,
	BEACONASSIST_LANDING,
	BEACONASSIST_LOGIN_MAIN_HEADER_TEXT_COLOR,
	BEACONASSIST_LANDING_LOGIN_SUB_HEADER_TEXT,
	BEACONASSIST_LOGIN_SUB_HEADER_TEXT_COLOR,
	BEACONASSIST_LANDING_LOGIN_MAIN_HEADER_TEXT,
	ONSHIP_LOGIN_MAIN_HEADER_TEXT,
	ONSHIP_LOGIN_SUB_HEADER_TEXT,
	ONSHIP_LANDING,
	ONECARE_LANDING,
	ONECARE_LOGIN_MAIN_HEADER_TEXT,
	ONECARE_LOGIN_SUB_HEADER_TEXT,
	ONECARE_MAIN_HEADER_TEXT_COLOR,
	ONECARE_SUB_HEADER_TEXT_COLOR,
	SIGMA_LANDING,
	SIGMA_LOGIN_MAIN_HEADER_TEXT,
	SIGMA_LOGIN_SUB_HEADER_TEXT,
} from "../../../Utils/Constants";
function LoginWelcomeText(props) {
	const { landingPath } = props;
	let mainHeader = "",
		subHeader = "",
		mainHeaderTextColor = "",
		subHeaderTextColor = "",
		fontSizeHeader = "2rem";

	switch (landingPath) {
		case VOYAGER_LANDING:
			mainHeader = VOYAGER_LOGIN_MAIN_HEADER_TEXT;
			subHeader = VOYAGER_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = VOYAGER_LOGIN_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = VOYAGER_LOGIN_SUB_HEADER_TEXT_COLOR;
			break;
		case VIKAND_LANDING:
			mainHeader = VIKAND_LOGIN_MAIN_HEADER_TEXT;
			subHeader = VIKAND_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = VIKAND_LOGIN_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = VIKAND_LOGIN_SUB_HEADER_TEXT_COLOR;
			break;
		case BEACONASSIST_LANDING:
			mainHeader = BEACONASSIST_LANDING_LOGIN_MAIN_HEADER_TEXT;
			subHeader = BEACONASSIST_LANDING_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = BEACONASSIST_LOGIN_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = BEACONASSIST_LOGIN_SUB_HEADER_TEXT_COLOR;
			break;
		case VIKAND_DIRECT_LANDING:
			mainHeader = VIKAND_LOGIN_MAIN_HEADER_TEXT;
			subHeader = VIKAND_DIRECT_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = VIKAND_LOGIN_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = VIKAND_LOGIN_SUB_HEADER_TEXT_COLOR;
			break;
		case INTELL4DESK_LANDING:
			mainHeader = INTELL4DESK_LOGIN_MAIN_HEADER_TEXT;
			subHeader = INTELL4DESK_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = INTELL4DESK_LOGIN_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = INTELL4DESK_LOGIN_SUB_HEADER_TEXT_COLOR;
			break;
		case THURAYA_LANDING:
			mainHeader = THURAYA_LOGIN_MAIN_HEADER_TEXT;
			subHeader = THURAYA_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = THURAYA_LOGIN_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = THURAYA_LOGIN_SUB_HEADER_TEXT_COLOR;
			break;
		case STATION_SATCOM_LANDING:
			mainHeader = STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT;
			subHeader = STATION_SATCOM_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = STATION_SATCOM_LOGIN_SUB_HEADER_TEXT_COLOR;
			break;
		case AAGEHEMPEL_LANDING:
			mainHeader = AAGEHEMPEL_LOGIN_MAIN_HEADER_TEXT;
			subHeader = AAGEHEMPEL_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = AAGEHEMPEL_LOGIN_MAIN_HEADER_TEXT;
			// subHeaderTextColor = AAGEHEMPEL_LOGIN_SUB_HEADER_TEXT;
			break;
		case ONSHIP_LANDING:
			mainHeader = ONSHIP_LOGIN_MAIN_HEADER_TEXT;
			subHeader = ONSHIP_LOGIN_SUB_HEADER_TEXT;
			fontSizeHeader = "1.4rem";
			break;
		case SIGMA_LANDING:
			mainHeader = SIGMA_LOGIN_MAIN_HEADER_TEXT;
			subHeader = SIGMA_LOGIN_SUB_HEADER_TEXT;
			fontSizeHeader = "1.4rem";
			break;
		case ONECARE_LANDING:
			mainHeader = ONECARE_LOGIN_MAIN_HEADER_TEXT;
			subHeader = ONECARE_LOGIN_SUB_HEADER_TEXT;
			mainHeaderTextColor = ONECARE_MAIN_HEADER_TEXT_COLOR;
			subHeaderTextColor = ONECARE_SUB_HEADER_TEXT_COLOR;
			break;
	}

	return (
		<div>
			<div
				className="text-center"
				style={{ color: mainHeaderTextColor, fontSize: fontSizeHeader }}
			>
				{mainHeader}
			</div>
			<p
				className="text-center"
				style={{
					color: subHeaderTextColor,
					fontSize: "1.3rem",
					fontWeight: "normal",
				}}
			>
				{subHeader}
			</p>
		</div>
	);
}

export default LoginWelcomeText;
