import React, { Component } from "react";
import { Link } from "react-router-dom";
import GoogleLoginButton from "./GoogleLoginButton";
import FrontMLogin from "./FrontMLogin";

import {
	AAGEHEMPEL_LANDING,
	AAGEHEMPEL_TITLE,
	ONSHIP_TITLE,
	FRONTM_TITLE,
	ONSHIP_LANDING,
	THURAYA_T2M_LANDING,
	THURAYA_LANDING,
	THURAYA_TITLE,
	VIKAND_LANDING,
	VIKAND_DIRECT_LANDING,
	VIKAND_TITLE,
	THURAYA_T2M_TITLE,
	BEACONASSIST_LANDING,
	BEACON_ASSIST_TITLE,
	STATION_SATCOM_LANDING,
	ONECARE_LANDING,
	ONECARE_TITLE,
	STATION_SATCOM_TITLE,
	SIGMA_LANDING,
	SIGMA_TITLE,
} from "../../Utils/Constants";
import history from "../../Services/History";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			corporateSignup: false,
			signUpPath: "/signup",
			showSignUpLink: [AAGEHEMPEL_LANDING, THURAYA_T2M_LANDING],
		};
	}

	componentDidMount() {
		let state = this.props && this.props.location && this.props.location.state;
		if (state && state.corporateSignup) {
			this.setState({ corporateSignup: true });
		} else {
			this.setState({ corporateSignup: false });
		}
		switch (this.props.landingPath) {
			case AAGEHEMPEL_LANDING:
				window.document.title = AAGEHEMPEL_TITLE;
				break;
			case ONSHIP_LANDING:
				window.document.title = ONSHIP_TITLE;
				break;
			case THURAYA_T2M_LANDING:
				window.document.title = THURAYA_T2M_TITLE;
				break;
			case THURAYA_LANDING:
				window.document.title = THURAYA_TITLE;
				break;
			case VIKAND_LANDING:
				window.document.title = VIKAND_TITLE;
				break;
			case VIKAND_DIRECT_LANDING:
				window.document.title = VIKAND_TITLE;
				break;
			case BEACONASSIST_LANDING:
				window.document.title = BEACON_ASSIST_TITLE;
				break;
			case ONECARE_LANDING:
				window.document.title = ONECARE_TITLE;
				break;
			case STATION_SATCOM_LANDING:
				window.document.title = STATION_SATCOM_TITLE;
				break;
			case SIGMA_LANDING:
				window.document.title = SIGMA_TITLE;
				break;
			default:
				window.document.title = FRONTM_TITLE;
				break;
		}
		state && delete state.corporateSignup;
		state && history.replace({ state: { ...state } });
		if (this.state.showSignUpLink.includes(this.props.landingPath)) {
			this.setState({
				signUpPath: this.props.landingPath + "/signup",
			});
		}
	}

	showSocialLogin = () => {
		let orLogInText =
			this.props.landingPath === "/onship" ? "OR" : "Or log in with";
		if (
			this.props.landingPath === "/vikand" ||
			this.props.landingPath === "/beaconassist" ||
			this.props.landingPath === "/vikanddirect" ||
			this.props.landingPath === "/aagehempel" ||
			this.props.landingPath === "/sattrackland" ||
			this.props.landingPath === "/thuraya" ||
			this.props.landingPath === STATION_SATCOM_LANDING ||
			this.props.landingPath === ONECARE_LANDING ||
			this.props.landingPath === SIGMA_LANDING ||
			this.props.landingPath === ONSHIP_LANDING

		) {
			return null;
		} else
			return (
				<div>
					<div
						className="text-center sep-white mb-2"
						style={{ marginTop: "-0.5rem", display: 'flex', justifyContent: 'space-between' }}
					>
						<div style={{
							borderTop: '1px solid #bbd2f8',
							width: '44%',
							height: '0',
							marginTop: '2%',
						}}/>
						<span style={{ color: "#9c9ea7" }}>{orLogInText}</span>
						<div style={{
							borderTop: '1px solid #bbd2f8',
							width: '44%',
							height: '0',
							marginTop: '2%',
						}}/>
					</div>
					{/* TODO: Temp GoogleLogin commented*/}
					{/*<div className="d-flex justify-content-around" style={{ marginTop: '20px' }}>*/}
					{/*	<GoogleLoginButton {...this.props} />*/}
					{/*</div>*/}
				</div>
			);
	};

	render() {
		// console.log("login for vikand ", this.props);

		return (
			<div
				className="d-flex flex-column"
				style={{
					width:
						(this.props.landingPath === "/vikand" ||
							this.props.landingPath === "/beaconassist" ||
							this.props.landingPath === "/vikanddirect" ||
							this.props.landingPath === "/intelli4desks" ||
							this.props.landingPath === "/sigma" ||
							this.props.landingPath === "/onecare" ||
							this.props.landingPath == "/aagehempel") &&
						"300px",
				}}
			>
				<div>
					<div className="px-4 py-2">
						<FrontMLogin {...this.props} />
					</div>
					<div
						className={`p-4 flex ${this.props.landingPath === "/aagehempel" ? "d-none" : ""
							}`}
					>
						{this.showSocialLogin()}
						{(this.props.corporateSignup ||
							this.state.showSignUpLink.includes(this.props.landingPath)) && (
								<div className="text-center mt-2">
									<Link
										to={{
											pathname: this.state.signUpPath,
											state: "flush",
										}}
										className={`btn btn-install ${this.props.landingPath &&
											this.props.landingPath.replace("/", "")
											}-link`}
										style={{ textTransform: "none" }}
									>
										You don’t have an account? Sign up{" "}
										<i className="icon-arrow-right" />
									</Link>
								</div>
							)}
						<div style={{
							width: '100%',
							height: '110px',
							backgroundColor: '#EEF2FF',
							borderRadius: '8px',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: '30px'
						}}>
							<p>Download our app</p>
							<div style={{
								display: 'flex',
							}}>
								<a href="https://play.google.com/store/apps/details?id=com.frontm.onship" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
									<img src="/img/Google-play-icon.svg" alt="g-play--icon" />
								</a>
								<a href="https://apps.apple.com/us/app/onship/id1491731188" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
									<img src="/img/app-Store.svg" alt="app-store-icon" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
