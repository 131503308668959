import RPC from "./RPC";
import { queue } from "../gRPC/Generated/QueueService";
import store from "../../State/configureStore";
import Config from "./../../Utils/Config";
import _ from "lodash";
const io = require("socket.io-client");

let baseURL = Config.gRPCURL;

export default class QueueServiceClient {
	static setupQueueMessageStream(receiveIncoming) {
		let userData = store.getState().user ? store.getState().user : null;
		let email = userData.user.emailAddress;
		let socketParams;
		socketParams = {
			path: "/grpc/clientConn",
			// Config.envName == "development" ? "/grpc/clientConn" : "/clientConn",
			transports: ["polling", "websocket"],
			transportOptions: {
				polling: {
					extraHeaders: {
						sessionId: _.get(userData, "auth.sessionId", null),
					},
				},
				websocket: {
					extraHeaders: {
						sessionId: _.get(userData, "auth.sessionId", null),
					},
				},
			},
			reconnection: false,
			withCredentials: true,
			autoConnect: false
		};
		const socket = io(baseURL, socketParams);

		socket.on("connect", () => {
			console.info("%c SOCKET successfully connected!", "color: green;");
			socket.emit("getMessages");
		});

		socket.on("disconnect", (reason) => {
			console.info("SOCKET disconnected :", reason);
			if (reason !== "io client disconnect") {
				// the disconnection was initiated by the server, you need to reconnect manually
				socket.connect();
			}
		});

		socket.on(email, (data) => {
			if (typeof data.data === "string") {
				receiveIncoming(JSON.parse(data.data));
			} else {
				receiveIncoming(data.data);
			}
		});
		socket.on("reconnect_attempt", () => {
			console.info("SOCKET reconnect_attempt");
		});

		socket.on("reconnect", () => {
			console.info("SOCKET reconnect");
		});
		socket.on("connect_error", () => {
			console.error("SOCKET connect_error");
		});
		return socket;
	}

	static GetPaginatedQueueMessages(args) {
		return RPC.rpcCall(
			"/queue.QueueService/GetPaginatedQueueMessages",
			args,
			queue.QueueResponseList,
			(request) => {
				return queue.QueueMessageInput.encode(request).finish();
			},
			queue.QueueResponseList.decode,
			{},
			true
		);
	}
}
