export default {
	envName: "development",
	baseURL: "https://elbdev.frontm.ai",
	filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
	fileAPIDev: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com",
	imgUploadAPI: "https://gwdev.frontm.ai/devproxy/dev",
	contentURL: "https://gwdev.frontm.ai/devproxy/botLogos/",
	soundURL: "https://gwdev.frontm.ai/devproxy/sounds/",
	botFilesAPI: "https://gwdev.frontm.ai/devproxy/dev/botfile?path=",
	gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
	paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
	mapboxApiAccessToken:
		"pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
	gRPCURL: "https://gwdev.frontm.ai",
	webertcSignalServer: "gwdev.frontm.ai",
	mapURL: "https://devproxymaps.frontm.ai/styles/klokantech-basic/style.json",
	gnsDomain: "gnsTestBots",
	aageHempelDomain: "aagehempelEndUser",
	onshipDomain: "onshipEndUser",
	sattracklandDomain: "sattracklandEndUser",
	sipURI: "frontmsecureweb@pstn-dev.frontm.ai",
	sipPassword: "frontm@123^%",
	sipWebsocket: "wss://pstn-dev.frontm.ai:7089/ws",
	pingServerDataFile: "devServerData.json",
	getCurrentVersion: "https://gwdev.frontm.ai/devproxy/getCurrentVersion",
};
